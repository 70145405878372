@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&display=swap");

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.serif {
	font-family: "Noto Serif TC", serif;
}

html,
body {
	font-family: "Noto Sans TC", sans-serif;
	background-size: cover;
}
